<c-row [class]="class">
  <ng-container *ngIf="showSearchInput">
    <c-col xs="3">
      <input
        cFormControl
        placeholder="Search..."
        name="search"
        [formControl]="searchCtrl"
      />
    </c-col>
  </ng-container>
  <c-col lg="3">
    <input cFormControl type="date" [(ngModel)]="startDate" [max]="endDate" />
  </c-col>
  <c-col lg="3">
    <input
      cFormControl
      type="date"
      placeholder="Search..."
      [(ngModel)]="toDate"
      [min]="startDate"
      [max]="endDate"
    />
  </c-col>
  <c-col lg="1">
    <button cButton class="btn-info text-white" (click)="onSearchClick()">
      Search
    </button>
  </c-col>
</c-row>
