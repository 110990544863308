<c-toaster [placement]="'top-end'" class="p-3" position="fixed">
    <c-toast
      *ngFor="let toast of toastService.toasts"
      [class]="toast.className"
      [autohide]="true"
      (timer)="onTimerChange($event)"
      [delay]="toast.delay || 5000"
      header="info"
      [visible]="toast.visible"
      (hidden)="toastService.remove(toast)"
    >
    <c-toast-header>
      </c-toast-header>
      <c-toast-body>
        <p class="text-white">{{ toast.textOrTpl }}</p>
        <c-progress thin>
          <c-progress-bar [value]="percentage"></c-progress-bar>
        </c-progress>
      </c-toast-body>
    </c-toast>
</c-toaster>
